<template>
  <v-row justify="center">
    <v-dialog
      v-model="dialog"
      persistent
      width="500"
      scrollable
    >
      <v-card>
        <v-card-title>
          <h4 class="headtitle-email" align="center">Detail Kerusakan/Selisih </h4>
          <v-row>
            <v-col class="d-flex justify-end">
              <v-toolbar-items>
                <v-btn
                  icon
                  color="black"
                  dark
                  @click="setDialogDetail('dialogDetail')"
                >
                  <v-icon>mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="pb-0" cols="12">
              <h4>{{$_strings.order.BASP_REASON}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field
                disabled
                outlined
                class="caption"
                dense
                :value="items.baspTitle"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.EXPLANATION_OF_REASON}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-textarea
                no-resize
                outlined
                disabled
                class="caption"
                dense
                v-model="items.baspNotes"
                rows="5"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.TYPE_OF_GOODS}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-text-field
                disabled
                outlined
                class="caption"
                dense
                :value="items.baspCategory"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.DESCRIPTION}}</h4>
            </v-col>
            <v-col class="pt-0 pb-0" cols="12">
              <v-textarea
                no-resize
                outlined
                disabled
                class="caption"
                dense
                v-model="items.note"
                rows="5"
              ></v-textarea>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pb-0 pt-0" cols="12">
              <h4>{{$_strings.order.PROOF_OF_DIFFERENCE_IN_ACCEPTANCE}} / {{$_strings.order.DOCUMENTATION}}</h4>
            </v-col>
            <v-col v-if="items.imageUrl && !items.imageUrl.length">
              <p>
                <i>Belum ada dokumentasi</i>
              </p>
            </v-col>
            <v-col v-for="(item, index) in  items.imageUrl" :key="index" class="pt-0" cols="6">
              <viewer-image
                :src="item"
                height="200px"
              />
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

export default {
  props: {
    dialogDetail: Boolean,
    setDialogDetail: Function,
    setDetail: Object,
  },
  watch: {
    dialogDetail() {
      this.dialog = this.dialogDetail;
    },
    setDetail() {
      this.items = this.setDetail;
    },
  },
  data() {
    return {
      dialog: this.dialogDetail,
      valid: false,
      items: [],
      getDocument: [],
      documents: [],
    };
  },
};
</script>

<style lang="scss" scoped>
.headtitle-email{
  font-size: 16px;
  font-weight: bold;
}
</style>
