<template>
  <v-container fluid>
    <v-row dense justify="end" class="mb-2">
      <v-col cols="auto">
        <filter-list
          :filters="filters"
          @fetchBasp="setFilters"
        />
      </v-col>
      <v-col cols="12" sm="5" md="3">
        <v-text-field
          dense
          hide-details
          outlined
          @keyup.native.enter="setFilters"
          placeholder="Cari Nomor Pesanan"
          class="caption"
          v-model="search"
        >
          <template v-slot:prepend-inner>
            <v-icon @click="setFilters">
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <v-data-table
          :loading="isLoading"
          :headers="displayedHeader"
          :items="items"
          item-key="id"
          :options.sync="pagination"
          @click:row="rowClicked"
          :server-items-length="totalItems"
          :footer-props="{
            showCurrentPage: true,
            showFirstLastPage: true,
            'items-per-page-options': $_item_per_page,
            disablePagination: isLoading,
            disableItemsPerPage: isLoading
          }"
        >
          <template v-slot:[`item.shipmentTitle`]={item}>
            <span>{{ item.shipmentTitle }}</span>
            <br>
            <span>{{item.cargoPickupDate && dateFormat(item.cargoPickupDate) || '-'}}</span>
          </template>
          <template v-slot:[`item.transportName`]={item}>
            <span>{{ item.transportName }}</span>
            <br>
            <span>{{ item.platLicense }}</span>
          </template>
          <template v-slot:[`item.driverName`]={item}>
            <span>{{ item.driverName }}</span>
            <br>
            <span>{{ item.driverPhone }}</span>
          </template>
          <template v-slot:[`item.action`]="{ item }">
            <v-icon
              small
              class="mr-2"
              @click:row="dialogDetail = true (item)"
            >
              mdi-pencil
            </v-icon>
          </template>
          <template v-slot:[`footer.page-text`]="props">
            <span>
              {{$_strings.damageAndMissing.pageName}}
              <span v-if="items.length">
                {{props.pageStart}}-{{props.pageStop}} of {{props.itemsLength}}
              </span>
            </span>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <Detail
      :setDialogDetail="setDialog"
      :dialogDetail="dialogDetail"
      :setDetail="temporary"
    />
  </v-container>
</template>

<script>
import {
  dateFormat,
  skipEmptyStringObject,
  handleSortBy,
  handlerPagination,
  defaultPagination,
} from '@/helper/commonHelpers';
import Detail from './Dialog/Detail.vue';
import FilterList from './FilterList.vue';

export default {
  name: 'damage-and-missing-page',
  components: {
    Detail,
    FilterList,
  },
  data() {
    return {
      isLoading: false,
      dialogDetail: false,
      temporary: {},
      items: [],
      filter: '',
      headers: [
        {
          text: this.$_strings.damageAndMissing.headerCatatan,
          value: 'baspTitle',
        },
        {
          text: this.$_strings.damageAndMissing.headerNoPesanan,
          value: 'shipmentTitle',
        },
        {
          text: this.$_strings.damageAndMissing.headerNamaCustomer,
          value: 'shipperName',
        },
        {
          text: this.$_strings.damageAndMissing.headerRekanan3PL,
          value: 'transporterName',
        },
        {
          text: this.$_strings.damageAndMissing.headerKendaraan,
          value: 'transportName',
        },
        {
          text: this.$_strings.damageAndMissing.headerSopir,
          value: 'driverName',
        },
      ],
      pagination: defaultPagination(),
      totalItems: 0,
      search: this.$route.query.search || '',
      filters: {
        baspTitle: this.$route.query.baspTitle || '',
        shipperId: this.$route.query.shipperId || '',
        transportNo: this.$route.query.transportNo || '',
        driverName: this.$route.query.driverName || '',
        transportTypeName: this.$route.query.transportTypeName || '',
      },
    };
  },
  computed: {
    displayedHeader() {
      return this.headers.map((i) => ({
        ...i,
        class: 'white--text primary text-capitalize',
        cellClass: 'clickable',
      }));
    },
  },
  watch: {
    pagination: {
      handler(newVal) {
        handlerPagination(this, newVal);
        if (!this.isLoading) this.fetchBasp();
      },
      deep: true,
    },
  },
  methods: {
    dateFormat,
    skipEmptyStringObject,
    rowClicked(e, { item }) {
      this.$root.$loading.show();
      this.$_services.damageAndMissing.baspDetail(item.baspId)
        .then((res) => {
          this.$set(this, 'temporary', []);
          if (res.data) {
            this.$set(this, 'temporary', res.data);
            this.setDialog('dialogDetail');
          }
        }).finally(() => {
          this.$root.$loading.hide();
        });
    },
    fetchBasp() {
      const {
        page, itemsPerPage, sortBy, sortDesc,
      } = this.pagination;
      const filters = {
        page: page - 1,
        size: itemsPerPage,
        search: this.search,
        sort: handleSortBy({ defaultValue: 'createdAt,DESC', sortBy, sortDesc }),
        ...this.filters,
      };
      this.isLoading = true;
      this.$_services.damageAndMissing.baspList(this.skipEmptyStringObject(filters))
        .then((res) => {
          this.items = res.data.contents;
          this.totalItems = res.data.totalData;
        }).finally(() => {
          this.isLoading = false;
        });
    },
    setDialog(dialog) {
      this[dialog] = !this[dialog];
    },
    setFilters() {
      if (this.pagination > 1) {
        this.pagination = 1;
        return;
      }
      this.fetchBasp();
    },
    searchShipment() {
      if (this.search !== this.$route.query.search) {
        this.$router.replace({
          name: this.$route.name,
          query: {
            ...this.$route.query,
            search: this.search,
          },
        });
      }
    },
  },
};
</script>
