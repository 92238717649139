var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',{staticClass:"mb-2",attrs:{"dense":"","justify":"end"}},[_c('v-col',{attrs:{"cols":"auto"}},[_c('filter-list',{attrs:{"filters":_vm.filters},on:{"fetchBasp":_vm.setFilters}})],1),_c('v-col',{attrs:{"cols":"12","sm":"5","md":"3"}},[_c('v-text-field',{staticClass:"caption",attrs:{"dense":"","hide-details":"","outlined":"","placeholder":"Cari Nomor Pesanan"},nativeOn:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.setFilters($event)}},scopedSlots:_vm._u([{key:"prepend-inner",fn:function(){return [_c('v-icon',{on:{"click":_vm.setFilters}},[_vm._v(" mdi-magnify ")])]},proxy:true}]),model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',[_c('v-data-table',{attrs:{"loading":_vm.isLoading,"headers":_vm.displayedHeader,"items":_vm.items,"item-key":"id","options":_vm.pagination,"server-items-length":_vm.totalItems,"footer-props":{
          showCurrentPage: true,
          showFirstLastPage: true,
          'items-per-page-options': _vm.$_item_per_page,
          disablePagination: _vm.isLoading,
          disableItemsPerPage: _vm.isLoading
        }},on:{"update:options":function($event){_vm.pagination=$event},"click:row":_vm.rowClicked},scopedSlots:_vm._u([{key:"item.shipmentTitle",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.shipmentTitle))]),_c('br'),_c('span',[_vm._v(_vm._s(item.cargoPickupDate && _vm.dateFormat(item.cargoPickupDate) || '-'))])]}},{key:"item.transportName",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.transportName))]),_c('br'),_c('span',[_vm._v(_vm._s(item.platLicense))])]}},{key:"item.driverName",fn:function(ref){
        var item = ref.item;
return [_c('span',[_vm._v(_vm._s(item.driverName))]),_c('br'),_c('span',[_vm._v(_vm._s(item.driverPhone))])]}},{key:"item.action",fn:function(ref){
        var item = ref.item;
return [_c('v-icon',{staticClass:"mr-2",attrs:{"small":""},on:{"click:row":function($event){_vm.dialogDetail = true (item)}}},[_vm._v(" mdi-pencil ")])]}},{key:"footer.page-text",fn:function(props){return [_c('span',[_vm._v(" "+_vm._s(_vm.$_strings.damageAndMissing.pageName)+" "),(_vm.items.length)?_c('span',[_vm._v(" "+_vm._s(props.pageStart)+"-"+_vm._s(props.pageStop)+" of "+_vm._s(props.itemsLength)+" ")]):_vm._e()])]}}],null,true)})],1)],1),_c('Detail',{attrs:{"setDialogDetail":_vm.setDialog,"dialogDetail":_vm.dialogDetail,"setDetail":_vm.temporary}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }